
.Divider {
  margin-top:5vh;
  width: 100%;
  border-bottom: 2px solid #323339;
  background-color: #5F9E6A;
  min-height: 1vh;
  display: flex;
}



@media (max-width: 800px) {
  .Divider {
    margin-top: 3vh;
  }
}
