
.Main {
  width: 100%;
  background-color: #FAF7EF;
  min-height: 50vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.Selfie {
  border: 2px solid  #323339;
  border-radius: 10%;
  float: right;
  margin-right: 1vw;
}

.About-img {
  flex: 0 0 30%;
}

.About-text {
  flex: 0 0 50%;
}


@media (max-width: 800px) {
  .Main {
    margin-top: 3vh;
    flex-direction: column;
    font-size: 1.3rem;
  }
  .Selfie {
    max-width: 40vw;
  }
  .About-text {
    padding: 2rem;
  }
}
