*,
*:after,
*:before {
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-family: 'Slabo 13px', serif;
  background-color: #FAF7EF;
  font-size: 1.6rem;
  color: #323339;
  letter-spacing: .1rem;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

h2 {
  color: #E48D37;
  letter-spacing: .3rem;
}
h3 {
  color: #5F9E6A;
  letter-spacing: .1rem;
}
h4 {
  color: #E48D37;
  letter-spacing: .1rem;
}

a i {
  color: #323339;
  font-size: 4rem;
}

a {
  color: #5F9E6A;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.img-responsive {
  max-width: 100vw;
  height: auto;
  display: block;
}

.glyph{
  color: #323339;
  font-size: 3rem;
  padding-right: 1vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
}
